<template>
  <div>
    <div class="columns" 
      :class="{'half-height mb-0 overfllow-y-scroll-mobile': selected_item.id}">
      <div class="column is-flex is-flex-direction-column pb-0 overflow-auto">
        <div class="level p-0 m-0">
          <div class="level-left">
            <grid-col-manage :columnDefs="columnDefs.filter(f => !['action', 'name'].includes(f.field))"
              :hiden_columns="hiden_columns" @toggle_column="toggle_column"></grid-col-manage>
            <div class="level-item">Magazinat</div>
            <input class="input" style="margin: 5px; width: 300px" type="text" placeholder="Kerko..."
              v-model="global_search_filter" @input="onFilterTextBoxChanged">
          </div>
          <div class="level-right">
            <div class="level">
              <a @click.prevent="openModal('cities')" class="pr-2 mr-2 border-r ">
                <small>Qytete</small>
              </a>
              <a v-if="am_allowed('transactions.can_create_warehouses')" @click.prevent="create_warehouse">
                <i class="fa fa-plus"></i>
              </a>
            </div>
          </div>
        </div>
        <ag-grid-vue 
          style="width: 100%; min-height: 40vh; height:80vh;" 
          class="ag-theme-balham is-flex-grow-1"
          :columnDefs="columnDefs" :rowData="rowData.value" :rowSelection="rowSelection" :context="context"
          :localeText="localeText" :animateRows="true" @grid-ready="onGridReady">
        </ag-grid-vue>
      </div>
      <div class="column is-5" v-if="form_edit || form_create">
        <DaForm v-if="form_create" @submitted="submitWarehouse" :closes_form="true" @close="form_create = null"
          :form="form_create"></DaForm>
        <DaForm v-if="form_edit" @submitted="submitWarehouse" :closes_form="true" @close="form_edit = null"
          :form="form_edit"></DaForm>
      </div>
    </div>
    <div v-if="selected_item.id" class="mt-2">
      <div class="is-flex">
        <a @click.prevent="close_selected_item()" href="" class="delete is-small ml-auto mb-1 mr-2"></a>
      </div>
      <div class="columns border-t-2 border-slate-200 mt-3 overflow-auto">
        <div class="column is-4">
          <warehouse-details :selected_item="selected_item"></warehouse-details>
          {{selected_item}}
        </div>
        <div class="column is-8">
          <div class="">
            <div class="tabs is-fullwidth is-boxed is-size-7">
              <ul>
                <li :class="{'is-active': show_tab == 1}"><a @click.prevent="show_tab = 1">Shitje</a></li>
                <li :class="{'is-active': show_tab == 2}"><a @click.prevent="show_tab = 2">Blerje</a></li>
                <li :class="{'is-active': show_tab == 3}"><a @click.prevent="show_tab = 3">Porosi</a></li>
                <li :class="{'is-active': show_tab == 4}"><a @click.prevent="show_tab = 4">Rezervime</a></li>
                <li :class="{'is-active': show_tab == 5}"><a @click.prevent="show_tab = 5">Garanci</a></li>
              </ul>
            </div>
          </div>
          <div class="px-2" style="border:solid 1px #dbdbdb; border-width:0px 1px;">
            <div v-if="show_tab == 1"> 
              <!-- <customer-sales></customer-sales> -->
            </div>
            <div v-if="show_tab == 2"> 
              <!-- <customer-bookings></customer-bookings> -->
            </div>
            <div v-if="show_tab == 3">
              <!-- <customer-obligations></customer-obligations> -->
            </div>
            <div v-if="show_tab == 4"> 
              <!-- <customer-transport></customer-transport> -->
            </div>
            <div v-if="show_tab == 5">
              <!-- <customer-warranty></customer-warranty> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue3";
import DaForm from "@/components/daform/index.vue";
import ButtonsCell from "@/components/buttonscell/ButtonsCell.vue";
import createWarehouse from "./forms/warehouses/create";
import editWarehouse from "./forms/warehouses/edit";
import locale from "@/components/ag-grid/locale/al/al.js"
import WarehousesServices from "@/services/Warehouse";
import permissionMixin from '@/mixins/permissions-mixin.js'
import { $vfm } from 'vue-final-modal'
import socket from "@/socket.js";
import { defineAsyncComponent, onUnmounted, reactive, inject } from "vue"
import gridColManage from "@/components/grid_col_manage.vue";
import warehouseDetails from "./components/warehouse-details";
export default {
  mixins: [permissionMixin],
  components: {
    AgGridVue,
    DaForm,
    gridColManage,
    warehouseDetails
  },
  setup() {
    let rowData = reactive([])
    const toast = inject('toast')
    socket.connect()
    socket.on("welcome", data => {
      console.log("welcome", data)
    })
    socket.on("propagate", data => {
      if (data.action == "create") {
        var a = rowData.value
        a.push(data.data)
        rowData.value = [...a]
        toast.success(
          "Magazina u krijua <i>nga " + data.created_by.name + "</i>"
        );
      }
      if (data.action == "edit") {
        rowData.value = [...rowData.value.map(e => {
          if (e.id == data.data.id) {
            return data.data
          }
          return e
        })]
        toast.success(
          "Magazina u modifikua <i>nga " + data.created_by.name + "</i>"
        );
      }
    })
    onUnmounted(() => {
      socket.off("welcome");
      socket.off("propagate");
      socket.disconnect();
    })
    return {
      rowData
    }
  },
  beforeMount() {
    this.context = { componentParent: this };
  },
  data() {
    return {
      global_search_filter: "",
      context: null,
      selectedWarehouse: {},
      open_citiuser_namees: false,
      form_create: null,
      form_edit: null,
      gridApi: null,
      defaultColDef: {
        flex: 1,
        minWidth: 100
      },
      rowSelection: "single",
      localeText: null,
      hiden_columns: ['type_id_name', 'code', 'role', 'location', 'description', 'city_name', 'reservation_warehouse_name', 'priority_order', 'user_name'],
      selected_item: {},
      show_tab: 1,
      transit_warehouses: []
    }
  },
  computed: {
    columnDefs() {
      var a = [
        { headerName: "Emri", width: '300px', field: "name", filter: true, floatingFilter: true, sortable: true, resizable: true, pinned: 'left', show_in_form: true, hide: this.hiden_columns.includes("name") },
        {
          headerName: "Kategoria", field: "type_id_name", filter: true,
          cellRenderer: function (params) {
            let r = ""
            if (params.data.type_id == "EMPTY-STRING" || params.data.type_id == 'undefined')
              r = `<i class="type_id">[SKA KATEGORI]</i>`
            else
              r = params.data.type_id_name
            return r
          },
          floatingFilter: true, sortable: true, show_in_form: true, hide: this.hiden_columns.includes("type_id_name")
        },
        { headerName: "Kodi", field: "code", filter: true, floatingFilter: true, sortable: true, resizable: true, show_in_form: true, hide: this.hiden_columns.includes("code") },
        { headerName: "Adresa", field: "location", filter: true, floatingFilter: true, sortable: true, resizable: true, show_in_form: true, hide: this.hiden_columns.includes("location") },
        { headerName: "Qyteti", field: "city_name", filter: true, floatingFilter: true, sortable: true, resizable: true, show_in_form: true, hide: this.hiden_columns.includes("city_name") },
        { headerName: "Pershkrimi", field: "description", filter: true, floatingFilter: true, sortable: true, resizable: true, show_in_form: true, hide: this.hiden_columns.includes("description") },
        {
          headerName: "Roli", field: "role", filter: true, hide: this.hiden_columns.includes("role"),
          cellRenderer: function (params) {
            let r = ""
            if (params.data.role) {
              r = params.data.role
              if (params.data.role == '1')
                r = "Primar"
              if (params.data.role == '2')
                r = "Sekondar"
            }
            return r
          },
          floatingFilter: true, sortable: true, resizable: true, show_in_form: false
        },
        { headerName: "Personi pergjegjes", field: "user_name", filter: true, floatingFilter: true, sortable: true, resizable: true, show_in_form: false, hide: this.hiden_columns.includes("user_name") },
        { headerName: "Njesia e biznesit", field: "unit_name", filter: true, floatingFilter: true, sortable: true, resizable: true, show_in_form: true, hide: this.hiden_columns.includes("unit_name") },
        {
          headerName: "Magazina e rezervimit", field: "reservation_warehouse_name", hide: this.hiden_columns.includes("reservation_warehouse_name"),
          cellRenderer: function (params) {
            let r = ''
            if (params.data.warehouse_facility == '1') {
              if (!params.data.reservation_warehouse_name)
                r = `<i class="reservation_warehouse"> [E PAPERCAKTUAR] </i>`
              else
                r = params.data.reservation_warehouse_name
            }
            return r
          },
          filter: true, floatingFilter: true, sortable: true, resizable: true, show_in_form: true
        },
        { headerName: "Objekti i magazines", field: "facility_name", filter: true, floatingFilter: true, sortable: true, resizable: true, show_in_form: true, hide: this.hiden_columns.includes("facility_name") },
        {
          headerName: "Aktiv", field: "active", filter: true, hide: this.hiden_columns.includes("active"),
          cellRenderer: function (params) {
            let r = ""
            if (params.data.active == '0')
              r = `<i class="fa-regular fa-circle-xmark fa-lg"></i>`
            else if (params.data.active == '1')
              r = `<i class="fa-regular fa-circle-check fa-lg"></i>`
            return r
          },
          floatingFilter: true, sortable: true, resizable: true, show_in_form: false
        },
        {
          headerName: "Rendi i prioritetit", field: "priority_order", filter: true, hide: this.hiden_columns.includes("priority_order"),
          cellRenderer: function (params) {
            let r = ""
            if (params.data.priority_order == '1')
              r = "Rendi kryesor"
            else if (params.data.priority_order == '2')
              r = "Rendi 2"
            else if (params.data.priority_order == '3')
              r = "Rendi 3"
            return r
          },
          floatingFilter: true, sortable: true, resizable: true, show_in_form: false
        },
        {
          headerName: "Action",
          cellRenderer: ButtonsCell,
          cellRendererParams: this.context,
          resizable: true,
          show_in_form: true,
          pinned: 'right',
          field: "action",
          hide: this.hiden_columns.includes("action"),
        }
      ]
      if (this.form_create || this.form_edit) {
        return [...a.filter(e => e.show_in_form)]
      }
      return [...a]
    }
  },

  methods: {
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.global_search_filter)
    },
    open_cities_form() {
      this.open_cities = !this.open_cities
    },
    async open(mode, warehouse) {
      if (mode == "1") {
        if (this.am_allowed('transactions.can_edit_warehouses')) {
          this.form_create = null
          if (warehouse) {
            this.form_edit = null
            setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
            this.form_edit = { loading: true }
            this.form_edit = await editWarehouse(warehouse)
            this.selectedWarehouse = warehouse
            setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
          }
        } else {
          this.$toast.error('Nuk keni te drejta te modifikoni magazine.')
        }
      }
      if(mode == '0') {
        this.selected_item = (await WarehousesServices.getWarehouseData({guid: warehouse.guid}))
        console.log('...', this.selected_item)
        // setTimeout(async () => {
        //   let selected_category = {}
        //   selected_category[warehouse.type_id] = true
        //   this.attributes_per_item = await WarehousesServices.getCategoryAttributes(selected_category, warehouse)
        // })
      }
    },
    onGridReady(params) {
      this.gridApi = params.api
      this.gridApi.sizeColumnsToFit();
      this.gridColumnApi = params.columnApi
    },
    async create_warehouse() {
      this.form_edit = null
      setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
      this.form_create = { loading: true }
      this.form_create = await createWarehouse()
      setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
    },
    async submitWarehouse(w) {
      if (this.form_create != null) {
        var notify_text = ""
        let warehouse = await WarehousesServices.createWarehouse(w)
        var a = this.rowData.value
        a.push(warehouse)
        this.rowData.value = [...a]
        socket.emit("propagate", { action: "create", data: warehouse })
        notify_text = "Magazina u krijua me sukses!"
        this.form_create = null
        this.$root.selected_one = {}
      }
      else {
        w.guid = this.selectedWarehouse.guid
        let warehouse = await WarehousesServices.editWarehouse(w)
        this.rowData.value = [...this.rowData.value.map(wa => {
          if (wa.guid == warehouse.guid) wa = { ...warehouse }
          return wa
        })]
        socket.emit("propagate", { action: "edit", data: warehouse })
        notify_text = "Magazina u modifikua me sukses!"
        this.form_edit = null
        this.$root.selected_one = {}
      }
      setTimeout(() => {
        this.gridApi.sizeColumnsToFit();
      }, 100);
      this.$toast.success(notify_text)
    },
    openModal(modalName) {
      $vfm.show({
        component: defineAsyncComponent(
          () => import("./components/" + modalName + "-modal.vue")
        ),
      })
    },
    toggle_column(column) {
      if (this.hiden_columns.includes(column)) {
        this.hiden_columns = [...this.hiden_columns.filter(item => item != column)]
      } else {
        this.hiden_columns.push(column)
      }

      setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
    },
    close_selected_item() {
      this.selected_item = { ...{} }
    },
  },

  async created() {
    if (!this.am_allowed('transactions.can_see_warehouses')) {
      window.location.href = '/home'
    }
    this.localeText = locale.locale_al()
    this.rowData.value = await WarehousesServices.getWarehouses()
    // this.transit_warehouses = await WarehousesServices.getTransitWarehouses()

    // this.rowData.value.map(w => {
    //   if(!w.transit_warehouses) w.transit_warehouses = []
    //   this.transit_warehouses.map(gw => {
    //     if(w.guid == gw.warehouse){
    //       w.transit_warehouses.push(gw)
    //       console.log(w)
    //     }
    //   })
    //   return w
    // })


    this.gridApi.sizeColumnsToFit();
  },
  onBeforeDestroy() {
    socket.disconnect();
  },
}
</script>

<style>
.reservation_warehouse, .type_id {
  color: rgb(235, 8, 8);
}
.half-height {
  height: 40vh;
}
</style>
