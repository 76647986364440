import PointsOfSaleServices from "@/services/PointOfSale";
import WarehousesServices from "@/services/Warehouse";
import helpers from "@/helpers.js";
import CategoriesServices from "@/services/Category";

export default async function editWarehouse(wa) {
    var city = await PointsOfSaleServices.getCities();
    var main_unit = await WarehousesServices.getWarehouseMainUnits()
    var facility = await WarehousesServices.getWarehouseFacilities()
    var warehouse_categories = helpers.toTree(await CategoriesServices.getCategories("warehouses"), "id")

    // Gathering point
    var warehouses = await WarehousesServices.getWarehouses()
    var warehouse_transit_list = warehouses.filter(ware => ware.gathering_point == 1 && ware.guid != wa.guid)
    warehouse_transit_list = helpers.toTree(warehouse_transit_list, "id")
    // warehouse_transit_list = warehouse_transit_list.map(p => {
    //     p.label = p.name
    //     p.id = p.guid
    //     return p
    // })

    var single_warehouse_transits = await WarehousesServices.getSingleWarehouseTransit(wa)
    if(!wa.transit_warehouses) wa.transit_warehouses = []
    single_warehouse_transits.map(gw => {
        wa.transit_warehouses.push(gw)
    })
    var selected_transit_warehouses = {}
    if (wa.transit_warehouses && wa.transit_warehouses.length > 0) {
        wa.transit_warehouses.map(tw => {
            selected_transit_warehouses[tw.transit_warehouse] = tw.transit_warehouse_name
        })
    }

    var users = await PointsOfSaleServices.getUsers()
    var roles = []
    var priority_order = []

    roles.unshift(
        {label: "Primar", id: 1},
        {label: "Sekondar", id: 2}
    )
    priority_order.unshift(
        {label: "Rendi Kryesor", id: 1},
        {label: "Rendi 2", id: 2},
        {label: "Rendi 3", id: 3},
    )

    var warehouses_selected_categories = {}
    warehouses_selected_categories[wa.type_id] = wa.type_id_name

    return {
        name: "Modifiko magazinen",
        submit: {
            label: "Modifiko magazinen",
            action: "submitVendor",
        },
        fields: [
            [
                {
                    value: wa.code,
                    name: "code",
                    label: "Kodi",
                    type: "text-field",
                    required: true,
                    required_text: "Kjo fushe eshte e detyrueshme",
                    unique: {
                        model: "warehouse",
                        field: "code",
                        where: [
                            {
                                column: "guid",
                                condition: "!=",
                                value: wa.guid,
                            },
                        ],
                    },
                    style: 'min-width: 45%',
                    left_icon: "fa-solid fa-hashtag",
                },
                {
                    value: wa.name,
                    name: "name",
                    label: "Emri",
                    type: "text-field",
                    style: "min-width: 45%",
                    required: true,
                    required_text: "Kjo fushe eshte e detyrueshme",
                },
                {
                    value: wa.active,
                    name: "active",
                    label: "Aktiv",
                    type: "checkbox-field",
                    style: 'min-width: 2%',
                },
            ],
            [
                {
                    value: wa.description,
                    name: "description",
                    label: "Pershkrimi",
                    type: "text-field",
                    style: "min-width: 30%",

                },
                {
                    value: wa.role,
                    name: "role",
                    label: "Roli",
                    type: "select-field",
                    options: roles,
                    style: "min-width: 30%",
                },
                {
                    value: wa.person_in_charge,
                    name: "person_in_charge",
                    label: "Personi pergjegjes",
                    type: "select-field",
                    options: users,
                    style: "min-width: 30%",
                },
            ],
            [
                {
                    value: wa.warehouse_facility,
                    name: "warehouse_facility",
                    label: "Objekti i magazines",
                    type: "select-field",
                    options: facility,
                    selected_options: [],
                    style: "min-width: 20%",
                    required: true,
                    required_text: "Kjo fushe eshte e detyrueshme",
                },
                {
                    value: wa.priority_order,
                    name: "priority_order",
                    label: "Rendi i prioritetit",
                    type: "select-field",
                    options: priority_order,
                    style: "min-width: 20%",
                },
                {
                    value: wa.reservation_warehouse,
                    name: "reservation_warehouse",
                    label: "Magazina e rezervimit",
                    type: "select-field",
                    options: warehouses.filter(w => w.warehouse_facility === 2),
                    selected_options: [],
                    style: "min-width: 20%",
                    required: false,
                    required_text: "Kjo fushe eshte e detyrueshme",
                    vif: [
                        {field: 'warehouse_facility', value: 1}
                    ]
                }
            ],
            [
                {
                    value: wa.location,
                    name: "location",
                    label: "Adresa",
                    type: "text-field",
                    style: "min-width: 30%"
                },
                {
                    value: wa.business_unit,
                    name: "business_unit",
                    label: "Njesia e biznesit",
                    type: "select-field",
                    options: main_unit,
                    selected_options: [],
                    style: "min-width: 30%"
                },
                {
                    value: wa.city,
                    name: "city",
                    label: "Qyteti",
                    type: "select-field",
                    options: city,
                    selected_options: [],
                    style: "min-width: 30%",
                    required: true,
                    required_text: "Kjo fushe eshte e detyrueshme",
                },
            ],
            [
                {
                    name: 'type_id',
                    label: 'Kategoria',
                    type: 'tree-select',
                    style: 'min-width: 100%',
                    mode: 'single',
                    options: warehouse_categories,
                    selected_options: warehouses_selected_categories,
                    // required: true,
                },
            ],
            [
                {
                    value: wa.can_pickup,
                    name: "can_pickup",
                    label: "Pike dorezimi per klientet",
                    type: "checkbox-field",
                    style: 'min-width: 30%',
                },
                {
                    value: wa.gathering_point,
                    name: "gathering_point",
                    label: "Eshte pike grumbullimi",
                    type: "checkbox-field",
                    style: 'min-width: 30%',
                },
            ],
            [
                {
                    name: 'warehouse_transit',
                    label: 'Pikat e grumbullimit',
                    type: 'tree-select',
                    style: 'min-width: 100%',
                    mode: 'multiple',
                    options: warehouse_transit_list,
                    selected_options: selected_transit_warehouses,
                    vif: [{ field: "gathering_point", value_not: 1 }],
                    // vif: [ {field: 'gathering_point', value: 0} ]
                },
            ],
            
        ]
    };
}
