import PointsOfSaleServices from "@/services/PointOfSale";
import GenericServices from "@/services/Generic";
import WarehousesServices from "@/services/Warehouse";
import helpers from "@/helpers.js";
import CategoriesServices from "@/services/Category";

export default async function createWarehouse() {
    var cities = await PointsOfSaleServices.getCities();
    var contact_details = await GenericServices.getDetailsTypes({ related_entity: "warehouse" });
    var main_unit = await WarehousesServices.getWarehouseMainUnits()
    var facility = await WarehousesServices.getWarehouseFacilities()
    var warehouses = await WarehousesServices.getWarehouses()
    var warehouse_categories = helpers.toTree(await CategoriesServices.getCategories("warehouses"), "id")
    // Gathering point
    var warehouse_transit = warehouses.filter(ware => ware.gathering_point == 1)
    warehouse_transit = helpers.toTree(warehouse_transit, "id")

    var users = await PointsOfSaleServices.getUsers()
    var roles = []
    var priority_order = []


    roles.unshift(
        {label: "Primar", id: 1},
        {label: "Sekondar", id: 2}
    )
    priority_order.unshift(
        {label: "Rendi Kryesor", id: 1},
        {label: "Rendi 2", id: 2},
        {label: "Rendi 3", id: 3},
    )



    return {
        name: "Krijo Magazine",
        submit: {
            label: "Krijo",
            action: 'submitWarehouse'
        },
        fields: [

            [{
                value: '',
                name: 'code',
                label: 'Kodi',
                type: 'text-field',
                required: true,
                required_text: 'Kjo fushe eshte e detyrueshme',
                unique: {
                    model: 'warehouse',
                    field: 'code',
                    where: []
                },
                style: 'min-width: 45%',
                left_icon: 'fa-solid fa-hashtag'
            },
            {
                value: '',
                name: 'name',
                label: 'Emri',
                type: 'text-field',
                style: 'min-width: 45%',
                required: true,
                required_text: 'Kjo fushe eshte e detyrueshme'
            },
            {
                value: 0,
                name: "active",
                label: "Aktiv",
                style: "min-width: 2%",
                type: "checkbox-field",
            },
            ],
            [
                {
                    value: "",
                    name: "description",
                    label: "Pershkrimi",
                    type: "text-field",
                    style: "min-width: 30%",

                },
                {
                    value: "",
                    name: "role",
                    label: "Roli",
                    type: "select-field",
                    options: roles,
                    style: "min-width: 30%",
                    required: true
                },
                {
                    value: "",
                    name: "person_in_charge",
                    label: "Personi pergjegjes",
                    type: "select-field",
                    options: users,
                    style: "min-width: 30%",
                },
            ],
            [
                {
                    name: 'warehouse_facility',
                    label: 'Objekti i magazines',
                    type: 'select-field',
                    options: facility,
                    style: 'min-width: 20%',
                    required: true,
                    required_text: 'Kjo fushe eshte e detyrueshme'

                },
                {
                    name: "priority_order",
                    label: "Rendi i prioritetit",
                    type: "select-field",
                    options: priority_order,
                    style: "min-width: 20%",
                },
                {
                    name: 'reservation_warehouse',
                    label: 'Magazina e rezervimit',
                    type: 'select-field',
                    options: warehouses.filter(w => w.warehouse_facility === 2),
                    style: 'min-width: 20%',
                    // required: true,
                    required_text: 'Kjo fushe eshte e detyrueshme',
                    vif: [
                        {field: 'warehouse_facility', value: 1}
                    ]
                },
            ],
            [
                {
                    value: '',
                    name: 'location',
                    label: 'Adresa',
                    type: 'text-field',
                    style: 'min-width: 30%'
                },
                {
                    name: 'business_unit',
                    label: 'Njesia Kryesore',
                    type: 'select-field',
                    options: main_unit,
                    style: 'min-width: 30%',
                },
                {
                    name: 'city',
                    label: 'Qyteti',
                    required: true,
                    required_text: 'Kjo fushe eshte e detyrueshme',
                    type: 'select-field',
                    options: cities,
                    style: 'min-width: 30%'
                },
            ],
            [
                
                {
                    name: 'type_id',
                    label: 'Kategoria',
                    type: 'tree-select',
                    style: 'min-width: 100%',
                    options: warehouse_categories,
                    selected_options: [],
                    mode: 'single',
                    // required: true
                },
            ],
            [
                {
                    value: 0,
                    name: "can_pickup",
                    label: "Pike dorezimi per klientet",
                    type: "checkbox-field",
                    style: 'min-width: 30%',
                },
                {
                    value: 0,
                    name: "gathering_point",
                    label: "Eshte pike grumbullimi",
                    type: "checkbox-field",
                    style: 'min-width: 30%',
                },
            ],
            [
                {
                    name: 'warehouse_transit',
                    label: 'Pikat e grumbullimit',
                    type: 'tree-select',
                    style: 'min-width: 100%',
                    mode: 'multiple',
                    options: warehouse_transit,
                    selected_options: [],
                    vif: [{ field: "gathering_point", value_not: 1 }],
                    // required: true,
                },
            ],
            [
                {
                    data: [],
                    // required: true,
                    name: 'contact_details',
                    label: 'Kontakti',
                    type: 'multiplexer',
                    options: contact_details,
                    style: 'min-width: 48%'
                },
            ],
        ]
    }
}

